import React from 'react'
import LayoutGray from '../../assets/LayoutGray'


const Layout = ({ children }) => {
    return (
        <LayoutGray className=''>
            <div className='text-gray-400 text-center' id='opinioes'>
                <p className='text-xs' >Vantagens</p>

                
            </div>
            <div className=''
            >
                {children}
            </div>
        </LayoutGray>

    )
}
export default Layout