import React, { Component } from 'react'
import Layout from "./layout";
//import FadeIn from '../../assets/FadeIn'
import Slider from "react-slick";
import "../../assets/slick-carousel/slick/slick.css";
import "../../assets/slick-carousel/slick/slick-theme.css";


class Slick extends Component {

    render() {
        const settings = {
            infinite: true,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            fade: true,
            arrows: false,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 6000,
            pauseOnHover: true,
            cssEase: "linear",
            

        };
        return (
            <Layout >
                <div className="mx-auto max-w-screen-md">
                    <Slider {...settings} className='h-auto border border-white p-6 rounded-tl-full rounded-br-full text-white text-justify'>
                    {/* <div className='flex flex-col mx-auto md:flex-row justify-around justify-items-stretch max-w-screen-xl mx-auto text-gray-600 md:space-x-4 space-y-4 md:space-y-0'> */}
                   
                        <div className='text-justify p-4 text-md'>

                            
                                <p className='text-xl font-bold text-center'>Custos, Margem de contribuição e Precificação.</p>
                                <p ><br/>Você conhece sua margem de contribuição? </p>
                                    <p><br/>Tem segurança na elaboração de um orçamento? E quando oferece descontos? </p>
                                    <p><br/>Sabe qual é o ponto de equilíbrio de sua empresa?</p>
                                    
                                    <p><br/>O cálculo <b>adequado do custo</b> e da <b>margem de contribuição</b>, ajuda a empresa a posicionar melhor seus produtos e serviços, buscando o equilíbrio entre lucratividade e competitividade de mercado.</p>
                                    <p><br/>Nosso know-how permite que o micro e pequeno empreendedor, implante conceitos utilizados em multinacionais de forma simples, efetiva e segura.</p>
                                    
                            
                        </div>
                        <div className='text-justify p-4 text-md'>
                            
                            <p className='text-xl font-bold text-center'>Previsibilidade de <b>Resultados</b> e de <b>Caixa</b></p>
                                {/* <p>O cálculo <b>adequado do custo</b> e da <b>margem de contribuição</b>, ajuda a empresa a posicionar melhor seus produtos e serviços, buscando o equilíbrio entre lucratividade e competitividade de mercado.</p> */}

                                <p><br/>Se você aumentar em 20% suas vendas, qual será sua necessidade de capital de giro?</p>
                                    
                                <p><br/>Se renunciar a algum cliente, como pode se planejar?</p>
                                <p><br/>A dinâmica do mercado pode causar no empreendedor a sensação de completa imprevisibilidade. Mas simulando possíveis situações de mercado, é possível traçar diferentes estratégias para cada cenário.</p>
                                
                                <p><br/>O planejamento financeiro é uma ferramenta que quantifica e valoriza o seu planejamento estratégico ou simplesmente os planos e metas da organização, fazendo com que a tomada de decisão, seja mais segura e coerente com a estratégia organizacional. Maximizando as riquezas e o Capital de Giro</p>
                                
                            
                        </div>
                    
                
                    </Slider>
                </div>
            </Layout>
        );
    }
}


// const About = () => {
//     return (

//             <LayoutGray >
//                 <div className='flex flex-col mx-auto md:flex-row justify-around justify-items-stretch max-w-screen-xl mx-auto text-gray-600 md:space-x-4 space-y-4 md:space-y-0'>
                   
//                         <div className='text-justify p-4 border-2 rounded-tl-full rounded-br-full max-w-sm md:max-w-lg space-y-4'>

//                             <FadeIn>                                
//                                 <div className='text-xl font-bold text-center'>Custos, Margem de contribuição e Precificação.</div>
//                                 <p><br/>Você conhece sua margem de contribuição? </p>
//                                     <p><br/>Tem segurança na elaboração de um orçamento? E quando oferece descontos? </p>
//                                     <p><br/>Sabe qual é o ponto de equilíbrio de sua empresa?</p>
                                    
//                                     <p><br/>O cálculo <b>adequado do custo</b> e da <b>margem de contribuição</b>, ajuda a empresa a posicionar melhor seus produtos e serviços, buscando o equilíbrio entre lucratividade e competitividade de mercado.</p>
//                                     <p><br/>Nosso know-how permite que o micro e pequeno empreendedor, implante conceitos utilizados em multinacionais de forma simples, efetiva e segura.</p>
                                    
//                             </FadeIn>
//                         </div>
//                         <div className='text-justify p-4 border-2 rounded-tl-full rounded-br-full max-w-lg space-y-4'>
//                             <FadeIn>
//                             <div className='text-xl font-bold text-center'>Previsibilidade de <b>Resultados</b> e de <b>Caixa</b></div>
//                                 {/* <p>O cálculo <b>adequado do custo</b> e da <b>margem de contribuição</b>, ajuda a empresa a posicionar melhor seus produtos e serviços, buscando o equilíbrio entre lucratividade e competitividade de mercado.</p> */}

//                                 <p><br/>Se você aumentar em 20% suas vendas, qual será sua necessidade de capital de giro?</p>
                                    
//                                 <p><br/>Se renunciar a algum cliente, como pode se planejar?</p>
//                                 <p><br/>A dinâmica do mercado pode causar no empreendedor a sensação de completa imprevisibilidade. Mas simulando possíveis situações de mercado, é possível traçar diferentes estratégias para cada cenário.</p>
                                
//                                 <p><br/>O planejamento financeiro é uma ferramenta que quantifica e valoriza o seu planejamento estratégico ou simplesmente os planos e metas da organização, fazendo com que a tomada de decisão, seja mais segura e coerente com a estratégia organizacional. Maximizando as riquezas e o Capital de Giro</p>
                                
//                             </FadeIn>
//                         </div>
                    
//                 </div>

//             </LayoutGray>

//     )
// }
export default Slick