import React from 'react'
import './styles.css'

import SEO from '../components/Seo.js'
import Navbar from '../components/header/navbar.js'
//import Top from '../components/header/top.js'
import About from '../components/about/about.js'
import About1 from '../components/about/about1.js'
import Servicos from '../components/servicos/items.js'
import Equipe from '../components/team/equipe.js'
import Opiniao from '../components/opinioes/slider.js'
import Contato from '../components/contato/contact.js'
import Footer from '../components/footer/footer'


const Index = () => {
    return (
        <main>
            <SEO />
            <Navbar />
            {/* <Top /> */}
            <About1 />
            <About />
            <Servicos />
            <Opiniao />
            <Equipe />
            <Contato />
            <Footer />
        </main>
    )
}

export default Index