import React from 'react'

const LayoutTeam = ({children}) => {
    return (
        <div className='m-6 text-center mx-auto text-gray-700' id='equipe'>
            <div>
                <p className='text-xs' >Equipe</p>
                <p className='text-lg font-semibold'>Responsável</p>
            </div>

        {children}
        </div>
    )
}

export default LayoutTeam