import React from 'react'

const Servicos = ({ children }) => {

    return (

        <div className='text-center text-gray-700 block rela' id='services'
        >


            <p className='text-xs' >Servicos</p>
            <p className='text-lg font-semibold'>Nossos Serviços</p>

            <div className='m-4 mx-auto max-w-screen-xl flex flex-wrap lg:flex-row'>

            {children}

            </div>
        </div>
    )
}

export default Servicos