import React from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'



const About1 = () => {

    const data = useStaticQuery(graphql`
    query home {

        file(relativePath: {eq: "businessman2.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 300, maxHeight: 150, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

    }
    `
    )

    return (
        <div>
            <div className='p-10 mt-10 max-w-screen-xl flex flex-wrap mx-auto justify-center space-y-3 md:space-y-0 space-x-0 lg:space-x-5'>

                <Img className="max-w-md w-full" fluid={data.file.childImageSharp.fluid} />


                <div className='max-w-md w-full text-justify text-gray-800 '>
                    <h1 className='text-xs'>Sobre nós</h1>
                    
                        <p className='text-xl font-bold'>Bem-Vindo a Controll Max</p> 
                        <p className=''>Consultoria e Assessoria Empresarial</p>
                        
                        <div className='space-y-4 mt-4'>
                        <p className='text-md '>
                            Com soluções personalizadas e aderentes a micro, pequenas e médias empresas, aplicamos a gestão financeira e administrativa, como ferramenta no desenvolvimento do sonho de empreender, elevando os padrões de gestão e resultados das organizações.
                </p>

                        <p className='text-md' >Nossa <b> missão</b> é suportar o empreendedorismo íntegro e consciente, provendo informações, controles, análises e modelos financeiros, que qualifiquem as tomadas de decisões fornecendo <b>previsibilidade</b>. Visando sua saúde financeira, sustentabilidade e crescimento.</p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default About1