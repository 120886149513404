import React from 'react'
import Icon1 from '../../assets/icons/icone01.png'
import Icon2 from '../../assets/icons/icone02.png'
import Icon3 from '../../assets/icons/icone03.png'
import FadeIn from '../../assets/FadeIn'
import Servicos from './servicos.js'




const Items = () => {

    return (
        <Servicos>

                {/* Card1 */}
                <div className='mx-auto p-2 max-w-sm  mt-4 lg:mt-0 text-justify shadow-lg text-justify'
                    data-aos="fade-up">

                    <FadeIn>
                        <img className='p-2 mx-auto' src={Icon2} alt='n' />


                        <p className='font-semibold text-center mt-2 mb-2 items-start'>Treinamentos</p>
                        <p>Capacitação de sócios e colaboradores em finanças - Modular</p>
                        <ul className='list-disc list-inside'>
                            <li>Métodos de custeio</li>
                            <li>Cálculo de Margem de Contribuição e Ponto de Equilíbrio</li>
                            <li>Precificação</li>
                            <li>Previsões e Simulador Financeiro – Orçamento e Modelagem Financeira</li>
                            <li>Criação, cálculo e análise de indicadores financeiros</li>
                            
                        </ul>
                    </FadeIn>

                </div>
                {/* Card2 */}
                <div className='mx-auto p-2 max-w-sm  mt-4 lg:mt-0 text-justify shadow-lg'
                // data-sal="slide-left"
                // data-sal-delay="600"
                // data-sal-easing="ease"
                // once='true'
                >
                    <FadeIn>

                        <img className='m-2 mx-auto' src={Icon1} alt='n' />
                        <div className='font-semibold text-center mt-2 mb-2'>Planejamento Financeiro</div>
                        <p>Identificação e definição das metas estratégicas do curto ao logo prazo.</p>
                        <p>Elaboração e estruturação do planejamento financeiro, para sustentar o plano estratégico.</p>   
                        <p className='mt-2'>Fundamental para previsibilidade de resultados e caixa, mapeando e antecipando decisões preventivas e contingenciais, aumentando as chances de atingimento das metas.</p>

                    </FadeIn>
                </div>
                <div className='mx-auto p-2 max-w-sm  mt-4 xl:mt-4 text-justify shadow-lg'
                // data-sal="slide-left"
                // data-sal-delay="900"
                // data-sal-easing="ease"
                >
                    <FadeIn>
                        <img className='m-2 mx-auto' src={Icon3} alt='n' />
                        <div className='font-semibold text-center mt-2 mb-2'>Custos e Preços</div>
                        <p>Cálculo do custo real, da margem de contribuição e do ponto de equilíbrio</p>

                        <p className='mt-2'>Métodos de alocações e rateios dos custos fixos. Apuração realista de resultados. </p>

                        <p className='mt-2 font-semibold'>Bases e técnicas para precificação; análise de preço, margem e giro de estoque.</p>
                    </FadeIn>
                </div>

        </Servicos>
    )
}



export default Items