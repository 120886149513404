import React from "react";
import './style.css'

export default class Email extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/xeqrrqgr"
        method="POST"
        className="w-full max-w-screen-md bg-black bg-opacity-75 border-2 rounded-tl-full rounded-br-full mt-6 md:mt-2"
        id='email'
      >
        <div className='p-6 flex flex-col '>
          <div className='flex flex-wrap -mx-3 mb-4 '>
            <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
              <label >Nome

              <input className="bg-gray-900 bg-opacity-75 appearance-none block w-full mt-1 border-2 border-black rounded py-3 px-4 leading-tight focus:outline-none bg-transparent focus:bg-white focus:text-black" required="required" pattern="[a-zA-Záãâéêíîóôõú\s]+$" type="text" placeholder="Nome" name='name'/>
              </label>
            </div>


            <div className='w-full md:w-1/2 px-3 md:mb-0'>
              <label >Empresa

                <input className='bg-gray-900 bg-opacity-75 text-white appearance-none block w-full mt-1 border-2 border-black rounded py-3 px-4 leading-tight focus:outline-none bg-transparent focus:bg-white focus:text-black' type="text" required="required" name="company" id='company' placeholder="Empresa S.A" />
                </label>
            </div>

          </div>

          <div className='w-full mb-4'>
            <label >Email

            <input className='bg-gray-900 bg-opacity-75 text-white appearance-none block w-full mt-1 border-2 border-black rounded py-3 px-4 leading-tight focus:outline-none bg-transparent focus:bg-white focus:text-black' type="email" name="mailbox" required="required" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="email@email.com" />
          </label>
          </div>
          <div className='flex flex-wrap -mx-3 md:mb-4'>
            <div className='w-full md:w-1/2 px-3 mb-4 md:mb-0'>
              <label >Telefone

              <input
                  className='bg-gray-900 bg-opacity-75 text-white appearance-none block w-full mt-1 border-2 border-black rounded py-3 px-4 leading-tight focus:outline-none bg-transparent focus:bg-white focus:text-black'

                  type="tel"
                  name="tel"
                  placeholder="(99) 9 9999-9999"
                  pattern="[0-9]+$"
                  maxlength="15"
                />
</label>
            </div>


            <div className='w-full md:w-1/2 px-3 mb-4 md:mb-0'>
              <label >Celular

              <input
                  className='bg-gray-900 bg-opacity-75 text-white appearance-none block w-full mt-1 border-2 border-black rounded py-3 px-4 leading-tight focus:outline-none bg-transparent focus:bg-white focus:text-black'

                  type="tel"
                  name="cel"
                  placeholder="(99) 9 9999-9999"
                  pattern="[0-9]+$"
                  maxlength="15"
                  required="required" />
            </label>
            </div>
          </div>

          <label >Mensagem

          <textarea className='bg-gray-900 bg-opacity-75 text-white appearance-none block w-full h-32 max-h-full mt-1 border-2 border-black rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:text-black'
              type="text"

              name="message"
              placeholder="Digite sua mensagem"
              required='required'
              wrap='hard'

            />
</label>



          <button className='bg-black bg-opacity-75 border-2 rounded-tl-btn rounded-br-btn  mt-4 text-center h-16 w-32 hover:text-black hover:bg-white hover:border-black'>Enviar</button>


          {status === "SUCCESS" && <p>Email enviado!</p>}
          {status === "ERROR" && <p>Ooops! Deu algo errado.</p>}

        </div>


      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}