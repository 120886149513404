import React from 'react'
import { Helmet } from 'react-helmet'
import favicon from '../assets/images/logo2.png'
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"


const SEO = ({type, lang, meta}) => {

    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    keywords
                    author
                    robots
                    copyright
                }
            }
        }
    `)
    const metaDescription = site.siteMetadata.description

    return (
        <Helmet
            htmlAttributes={{
                lang,
                type
            }}
            title={site.siteMetadata.title}
            //titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: site.siteMetadata.title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },

                {
                    name: `creator`,
                    content: site.siteMetadata.author,
                },

                {
                    name: `keywords`,
                    content: site.siteMetadata.keywords
                },
                {
                    name: `robots`,
                    content: site.siteMetadata.robots
                },
                {
                    name: `copyright`,
                    content: site.siteMetadata.copyright
                },



            ]

                .concat(meta)}


        >

            <link rel="icon" href={favicon} />

        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `pt-BR`,
    type: `text/html`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO