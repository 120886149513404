import React from 'react'

const LayoutGray = ({children}) => {
    return (
    <div className='mt-10 mb-10 p-10 bg-gray-900 space-y-6 '>

        {children}
    </div>
    )
}

export default LayoutGray