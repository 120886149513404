import React from 'react'
import Email from './email'
import './style.css'
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby'

const Contato = () => {
    const footerQuery = useStaticQuery(graphql`
  query {
    footerImage: file(relativePath: {eq: "bg-contact.jpeg"}) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

    return (
        <div id='contato'>
            <BackgroundImage id='contact'
                fluid={footerQuery.footerImage.childImageSharp.fluid}
            >
                <div className='mx-auto text-white p-1 w-full max-w-screen-xl mx-auto px-6 py-6' >
                    <div className='text-center '>
                        <p className='text-xs' >Contato</p>
                        <p className='text-lg font-semibold'>Entre em Contato</p>
                    </div>

                    <div className="flex flex-col md:flex-row mt-6 mb-6 justify-around "

                    >
                        <div className='flex flex-col text-justify md:max-w-sm md:mr-3'>

                            <p>Entre em contato deixando-nos uma mensagem, e-mail, WhatsApp, telefone, Instagram e Facebook para dúvidas e orçamentos, ou nos faça uma visita pessoalmente, confira a baixo:</p>


                            {/*Endereço*/}
                            <div className='border-2 rounded-tl-full rounded-br-full p-2 md:p-6 mt-6 md:mt-4 bg-black bg-opacity-75'>
                                <div className='m-2'>
                                    <p className='font-semibold'>Endereço</p>
                                    <p>Avenida Dr João Teodoro, 25 - Sala 5</p>
                                    <p>Piracicaba - São Paulo</p>
                                    <p>Brasil</p>
                                </div>
                                <div className='m-2 mt-4'>
                                    <p className='font-semibold'>Telefone</p>
                                    <p>+ 55 (19) 9 8234 3381</p>
                                </div>
                                <div className='m-2 mt-4'>
                                    <p className='font-semibold'>Email</p>
                                    <p>bruno.santos@controllmax.com.br</p>
                                </div>
                            </div>
                        </div>

                        <Email />

                    </div>
                </div>
            </BackgroundImage>
        </div>


    )
}

export default Contato