import React, { Component } from "react";
import Layout from "./layout";
import Slider from "react-slick";
import "../../assets/slick-carousel/slick/slick.css";
import "../../assets/slick-carousel/slick/slick-theme.css";


const data = [
    {
        "_id": Math.random(),
        "index": 0,
        "picture": "https://sistem.eng.br/wp-content/themes/sistem.eng.br/assets/img/header-logo-green.svg",
        "description": "Inicialmente, em busca de precisão dos números e dados para que pudéssemos vender nossos serviços com segurança em nosssas margens, fomos em busca de um profissional do setor financeiro. A Controll Max nos surpreendeu com o engajamento na solução de problemas financeiros atuais e, muito além, em fornecedor o caminho para a solução dos próximos desafios",
        "responsavel": "Rafael Domingues",
        "cargo": "Sócio"
    },
    {
        "_id": Math.random(),
        "index": 1,
        "picture": "https://scontent.fcpq5-1.fna.fbcdn.net/v/t1.6435-1/p200x200/89985631_106398154330410_4902840081896701952_n.jpg?_nc_cat=109&ccb=1-3&_nc_sid=dbb9e7&_nc_ohc=muxXoggwJ3wAX9D3wh2&_nc_ht=scontent.fcpq5-1.fna&tp=6&oh=a2dd44c2b934e297b183e0118113a8e4&oe=60B0B4CF",
        "description": "Em novembro 2019 tivemos um sério problema financeiro, assumimos grandes dívidas com cinco instituições bancárias. Sem saber para onde correr, no mês de julho de 2020 tivemos a ideia de procurarmos ajuda profissional na área de finanças.  Foi quando contratamos a era uma situação bem complicada e delicada. Foi onde explicamos todo o processo de como trabalhávamos e a partir disso, juntos fizemos novos projetos e novas metas. Refizemos toda nossa forma financeira de trabalho, a consultoria treinou e capacitou o funcionário na área, fez reuniões com todos os gerentes dos bancos e com a graça de Deus e muito trabalho, fomos pagando um a um. Hoje a saúde financeira da Bellagio é completamente estável, onde podemos projetar e consolidar ótimos resultados. Nossa empresa mudou completamente, foi um divisor de águas. Porque eu digo que foi uma ajuda e não só trabalho, porque esse cara tem alguma coisa que não é só trabalho, não é só ser um bom profissional, da forma que ele se entrega é diferente, em cada dívida que íamos conseguindo eliminar ele vibrava e cada conquista e melhorias que íamos tendo ele fica extremamente feliz, e isso me contagiava e me motivava mais ainda.  Por isso eu digo, Bruno você não ganhou só um cliente, mas um amigo!",
        "responsavel": "Wellington Emídio",
        "cargo": "Sócio"
    },


]

class Slick extends Component {

    render() {
        const settings = {
            adaptiveHeight: true,
            infinite: true,
            dots: true,
            slidesToShow: 1,
            swipeToSlide: true,
            fade: true,
            arrows: false,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 6000,
            pauseOnHover: true,
            cssEase: "linear",
            focusOnSelect: false,

        };
        return (
            <Layout >
                <div className="mx-auto max-w-screen-lg ">
                    <Slider {...settings} className=''>
                        {data.map((dados) => {
                            return (
                                <div className="h-auto border border-white p-6 rounded-tl-full rounded-br-full text-white text-justify" src={dados._id} >
                                    <img className='mx-auto h-16' src={dados.picture} alt='Clientes' />
                                    <div className="mt-4">
                                        <p>{dados.description}</p>
                                        <p className="font-bold mt-2">{dados.responsavel}</p>
                                        <p >{dados.cargo}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </Layout>
        );
    }
}


export default Slick
